<template>
  <div class="block-contact">
    <TopMenu
      componentsInterface="catalog"
      :loading="getCustomContactsProcessing && !modalEdit && !modalDeleted"
      :error="
        getCustomContactsError && !modalEdit && !modalDeleted
          ? getCustomContactsError
          : null
      "
      :show="showActionHeaderEditable"
      :checkTable="selectedCheck"
      @searchLocation="searchLocation"
    ></TopMenu>
    <dataImmoTable
      :Loader="getCustomContactsProcessing"
      :Total="countPaginationContact"
      :computedHeaderColumnTable="computedHeaderColumnTable"
      :computedTableBoard="computedTableBoard"
      :computedLastPage="computedLastPage"
      @pagination="pagination"
      @SlectedItems="selectedCheckTable"
      @openModalFileProjet="handleUpdateClick"
      @deleted="deletedContact"
      :computedRestPage="computedRestPage"
      :styleComponents="'catalog'"
      :showActionHeaderEditable="showActionHeaderEditable"
    >
    </dataImmoTable>
    <ModalContact
      v-if="modalEdit"
      :data="modalUpdate"
      labelModal="contact"
      :typeCrud="$t('update')"
      @close="closeModal"
      @valider="valider"
    ></ModalContact>
    <ModalDeleted
      v-if="modalDeleted"
      :label="'contact'"
      @handleClose="modalDeleted = false"
      @handleDeleted="handleDeleted"
      :selectedCheck="selectedCheckDeleted"
      :loader="getCustomContactsProcessing"
    ></ModalDeleted>
  </div>
</template>
<script>
import TopMenu from '@/views/CRMILA26/Projects/immobilier/pipline/Contact/TopMenu.vue'
import ModalContact from '@/views/CRMILA26/Projects/immobilier/pipline/Contact/ModalContact.vue'
import dataImmoTable from '@/views/CRMILA26/Projects/immobilier/pipline/immoTable/dataImmoTable.vue'
import ModalDeleted from '@/views/CRMILA26/Projects/immobilier/pipline/Catalog/ModalDeleted.vue'

import { mapGetters, mapActions } from 'vuex'
export default {
  components: { TopMenu, dataImmoTable, ModalContact, ModalDeleted },
  data() {
    return {
      showActionHeaderEditable: false,
      selectedCheck: null,
      page: 1,
      per_page: 10,
      restPage: false,
      modalUpdate: null,
      modalUpdateOrigin: null,
      modalEdit: false,
      location: null,
      modalDeleted: false,
      selectedCheckDeleted: null
    }
  },
  methods: {
    ...mapActions([
      'fetchHeaderCustomContacts',
      'fetchBodyCustomContacts',
      'updateCustomContact',
      'deletedCustomContact',
      'resetErrorCustomContacts'
    ]),
    deletedContact(event) {
      // Créer une copie de l'objet event avec la propriété designation ajoutée
      const modifiedEvent = { ...event, designation: event.full_name }
      // Mettre à jour les propriétés de l'objet Vue
      this.modalDeleted = true
      this.selectedCheckDeleted = [modifiedEvent]
    },
    async handleDeleted() {
      if (this.selectedCheckDeleted && this.selectedCheckDeleted.length) {
        const reponse = await this.deletedCustomContact(
          this.selectedCheckDeleted[0].id
        )
        if (reponse) {
          this.modalDeleted = false
        }
      }
    },
    searchLocation(location) {
      this.location = location
      this.handelFilter()
    },
    handleUpdateClick(item) {
      let objet = { ...item }
      this.modalUpdate = {
        id: objet.id,
        full_name: objet.full_name,
        lead_owner: objet.lead_owner_id,
        email_adress: objet.email_adress,
        phone_number: objet.phone_number,
        nationality: objet.nationality_id,
        location: objet.location_id,
        job_title: objet.job_title_id,
        civility: objet.civility,
        contact_id: objet.contact_id
      }
      this.modalUpdateOrigin = item
      this.modalEdit = true
    },
    closeModal() {
      this.modalEdit = false
    },
    async valider(body, contact) {
      const reponse = await this.updateCustomContact({ body, contact })
      if (reponse) {
        this.resetErrorCustomContacts()
        this.closeModal()
      }
    },
    selectedCheckTable(event) {
      this.showActionHeaderEditable = event.length > 0
      this.selectedCheck = event
    },
    pagination(page) {
      this.page = page
      this.handelFilter()
    },
    async handelFilter() {
      await this.fetchHeaderCustomContacts()
      this.fetchBodyCustomContacts({
        page: this.page,
        per_page: this.per_page,
        location: this.location
      })
    }
  },
  computed: {
    ...mapGetters([
      'getCustomContactsProcessing',
      'getCustomContactsError',
      'countPaginationContact',
      'getBodyContact',
      'getHeaderContact'
    ]),
    computedRestPage() {
      return this.restPage
    },
    computedLastPage() {
      return Math.ceil(this.countPaginationContact / this.per_page)
    },
    computedHeaderColumnTable: function() {
      if (this.getHeaderContact && this.getHeaderContact.length) {
        return this.getHeaderContact
          .map(i => {
            return {
              text: i.name,
              value: i.key,
              width: 170,
              ...i
            }
          })
          .concat({ text: 'Actions', value: 'deleted', width: 80 })
      }

      return []
    },
    computedTableBoard: function() {
      if (this.getBodyContact && this.getBodyContact.length) {
        const newArray = this.getBodyContact
        return newArray
      }

      return []
    }
  },

  async mounted() {
    this.handelFilter()
  }
}
</script>
<style lang="scss" scoped>
.block-contact {
  width: 100%;
  padding: 6px 16px 6px 8px;
}
</style>
