<template>
  <div>
    <div class="header-block">
      <div class="left-header">
        <div class="list-class-proposals">
          <!-- BLOCK EDIT VIEW  -->
          <div class="item-list">
            <v-menu offset-y>
              <template v-slot:activator="{ attrs, on }">
                <div
                  color="transparent"
                  class="black--text btn-edit-view"
                  v-bind="attrs"
                  v-on="on"
                >
                  <span class="icon-edit-view">
                    <IconColumns />
                  </span>
                  <span class="ml-1 label-btn-edit-view ">
                    {{ $i18n.locale == 'fr' ? 'Modifier vue' : 'Edit View' }}
                  </span>
                </div>
              </template>

              <!-- <v-list>
                <v-list-item v-for="item in items" :key="item" link>
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list> -->
            </v-menu>
          </div>
          <div
            v-if="show"
            class="delete-projet ml-2 mt-3"
            @click.prevent.stop="handleDirectionTableContactId"
          >
            {{
              $i18n.locale == 'fr'
                ? 'Voir les opportunités'
                : 'View Opportunities'
            }}
          </div>
        </div>
      </div>
      <div class="right-header">
        <!-- ----------------   START LOADING / MESSAGE ERROR  ------------------------------>
        <div class="message mr-3">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="#45148F"
            class="sz-loader-moyen-icon"
          ></v-progress-circular>
          <div v-if="hasError" class="error-msg">
            <ul v-if="Array.isArray(errorMessages)">
              <li v-for="(e, index) in errorMessages" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ errorMessages }}</span>
          </div>
        </div>
        <!-- ----------------   END LOADING / MESSAGE ERROR  ------------------------------>

        <!-- ---------------------------- SELECTED FILTER ----------------------------- -->
        <DropDownCheckWithSearch
          :options="getContactPays"
          :loadingSelect="getContactsPaysProcessing"
          :updateFunction="filtreTableContactWidthLocation"
        ></DropDownCheckWithSearch>
      </div>
    </div>
    <v-divider class="mt-2"></v-divider>
  </div>
</template>
<script>
import IconColumns from '@/views/CRMILA26/Projects/immobilier/pipline/IconSvg/Columns.vue'
import DropDownCheckWithSearch from '@/views/CRMILA26/Projects/immobilier/pipline/immoTable/component/DropDownCheckWithSearch.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    loading: { required: true },
    error: { required: true },
    show: { required: true },
    checkTable: { required: true }
  },
  components: {
    IconColumns,
    DropDownCheckWithSearch
  },
  data() {
    return {
      openEdit: false,
      errorMessages: null,
      items: []
    }
  },
  methods: {
    ...mapActions(['fetchTableBoard', 'fetchListContactPays']),
    handleDirectionTableContactId() {
      if (this.checkTable && this.checkTable.length) {
        // Stocker les identifiants dans le stockage local
        localStorage.setItem('contacts_ids', JSON.stringify(this.checkTable))
        // Rediriger vers la page '/projects'
        this.$router.push('/projects')
      }
    },
    filtreTableContactWidthLocation(search) {
      this.$emit('searchLocation', search)
    }
  },
  computed: {
    ...mapGetters(['getContactPays', 'getContactsPaysProcessing']),
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  watch: {
    error(newValue) {
      this.errorMessages = newValue
    }
  },
  mounted() {
    this.fetchListContactPays()
  }
}
</script>
<style lang="scss" scoped>
.header-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left-header {
    white-space: nowrap;
    .delete-projet {
      color: #45148f;
      font-family: 'Montserrat', sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 24px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .right-header {
    display: flex;
    align-items: center;

    .btn-list {
      padding: 8px;
      gap: 10px;
      border-radius: 4px;
      width: 39px;
      height: 39px;
      text-align: center;
      &:hover,
      &.active {
        background-color: #ececec;
      }
    }
    .color-img {
      color: #7f8c8d !important;
    }
  }

  .block-action-add-propoasls {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    line-height: 24px;
    gap: 5px;
    letter-spacing: 0px;
    border-right: 1px solid #c9c8d3;
    align-items: center;
    justify-content: flex-start;
    .label-btn {
      font-size: 12px;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
      text-align: center;
      text-transform: capitalize !important;
      line-height: 18.2px;
    }
  }
  .list-class-proposals {
    display: flex;
    .item-list {
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      line-height: 24px;
      gap: 5px;
      letter-spacing: 0px;
      color: #2f2f2f;
      padding: 10px 14px 10px 12px;
      border-right: 1px solid #c9c8d3;
      align-items: center;
      justify-content: flex-start;
      &:hover {
        background: #f1f5f9;
      }
    }
  }
  .icon-edit-view {
    top: 5px;
    position: relative;
  }
  .label-btn-edit-view {
    font-size: 13px !important;
    position: relative;
    top: 2px;
    font-family: 'Montserrat', sans-serif !important;
    text-transform: capitalize !important;
  }
}
</style>
<style lang="scss">
.header-block {
  .block-action-add-propoasls {
    .v-btn:not(.v-btn--round).v-size--default {
      height: 33px !important;
    }
  }
}
</style>
