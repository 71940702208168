<template>
  <custom-drop-down-menu @eventMenu="eventMenu">
    <template #header>
      <div class="header-drop-down-immo" :class="{ active: actionMenu }">
        <div class="header-title">
          <div class="header-title">
            {{ $t(keyFilter) }} {{ computedTitle }}
            <v-icon class="icon-flech ml-1">mdi-chevron-down</v-icon>
          </div>
        </div>
      </div>
    </template>
    <template #body>
      <div
        class="drop-down-check"
        v-if="computedOptions && computedOptions.length"
      >
        <v-checkbox
          class="label-check ml-2"
          v-for="item in computedOptions"
          :key="item.id"
          :label="item[label]"
          :value="item"
          v-model="selectedCheck"
          color="#5C2DD3"
          @click="handelInputRadio(selectedCheck)"
          hide-details
        ></v-checkbox>
      </div>
      <div v-else class="font-text text-capitalize font-sz-12  text-center">
        {{ $t('noResult') }}
      </div>
      <v-divider class="pb-3 mt-2"></v-divider>
      <v-text-field
        class="field-search-immo"
        v-model="search"
        :append-icon="'mdi-magnify'"
        outlined
        hide-details
        single-line
        :label="$t('search')"
      ></v-text-field> </template
  ></custom-drop-down-menu>
</template>

<script>
import customDropDownMenu from './customDropDownMenu.vue'
import { debounce } from 'lodash'
export default {
  name: 'drop-down-check-with-search',
  props: {
    updateFunction: { default: () => {} },
    label: { default: 'name' },
    options: { required: true },
    loadingSelect: { required: false, default: false },
    keyFilter: { default: 'location' }
  },
  data() {
    return {
      search: null,
      selectedCheck: [],
      actionMenu: false
    }
  },
  computed: {
    computedOptions() {
      return this.search
        ? this.options.filter(item =>
            item[this.label].toLowerCase().includes(this.search.toLowerCase())
          )
        : this.options
    },
    computedTitle() {
      let selected = this.selectedCheck.length
        ? ' : ' + this.selectedCheck[0][this.label]
        : this.$i18n.locale === 'fr'
        ? ` : Toutes les localités`
        : ` : All Locations`
      let athor =
        this.selectedCheck.length && this.selectedCheck.length > 1
          ? ' +' + (this.selectedCheck.length - 1)
          : ''
      return selected + athor
    }
  },
  methods: {
    handelInputRadio: debounce(async function(item) {
      let body = item && item.length ? item.map(loc => loc.id) : []
      this.updateFunction(body)
    }, 400),
    eventMenu(event) {
      this.actionMenu = event
    }
  },
  components: { customDropDownMenu }
}
</script>

<style lang="scss">
.header-drop-down-immo {
  cursor: pointer;
  font-size: 12px !important;
  font-weight: 500;
  display: flex;
  gap: 7px;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 0px;
  color: #2f2f2f;
  padding: 9px 12px 7px 12px;
  align-items: center;
  justify-content: flex-start;
  background: #f1f5f9;
  &.active {
    background: #d3dde89e;
  }
  &:hover {
    background: #d3dde89e;
  }
  .header-title {
    font-size: 12px !important;
    font-family: 'Montserrat', sans-serif;
    line-height: 9px;
    color: #2f2f2f;
    font-weight: 500;
  }
  .header-title::first-letter {
    text-transform: uppercase;
  }
  .svg-inline--fa {
    height: 1.2em !important;
  }
}
.field-search-immo {
  fieldset {
    border: 2px solid #704ad1 !important;
  }
  .v-input__append-inner,
  .v-icon.v-icon {
    color: #704ad1 !important;
  }
  .v-text-field__slot,
  .v-text-field__slot .v-label {
    color: #505050 !important;

    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 10px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
  }
  .v-input__slot {
    min-height: 36px !important;
  }
  .v-input__append-inner {
    margin-top: 8px !important;
  }
  .v-label {
    top: 14px !important;
  }
  .v-icon.v-icon {
    font-size: 21px !important;
  }
}
.drop-down-check {
  margin-top: 0px;
  height: 165px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: #eee;
  }
  .v-input--selection-controls {
    margin-top: 10px;
    padding-top: 4px;
  }
}
</style>
